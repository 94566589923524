import Vue from 'vue';
import '@nsftx/games-sdk-js/src/utility/filters';
import { ConfigurationService } from '@nsftx/games-config';
import { gamesStatic, sentry } from '@nsftx/games-sdk-js';
import {
  assign,
  capitalize,
  upperFirst,
  lowerFirst,
  range,
  has,
} from 'lodash';
import LocalizedLabel from '@/components/LocalizedLabel';
import webViewIntegration from '@/utility/webViewIntegration';
import App from './App';
import router from './router';
import store from './store';
import i18n from './i18n';

Vue.config.productionTip = false;
Vue.use(LocalizedLabel);

const qp = new URLSearchParams(window.location.search);
const tenantId = qp.get('tenantId');
const deliveryPlatform = qp.get('deliveryPlatform') || qp.get('application') || 'web';
const messageProtocol = qp.get('messageProtocol') || qp.get('integrationType') || 'postMessage';
const layout = qp.get('layout');
const token = qp.get('token');
const clientType = qp.get('authStrategy') || qp.get('clientType');
const playerId = qp.get('id') || qp.get('playerId');
const deployment = window.location.hostname.includes('nsoft.app') ? 'cf' : 'netlify';
let deviceData;
let betshopData;
let companyData;
let accountData;
let productUuid = null;

window.addEventListener('message', (event) => {
  // Slave.Load message can occur before bus init
  if (event.data.action === 'Slave.Load') {
    productUuid = event.data.data.product.uuid;
  }
});

if (deliveryPlatform === 'terminal') {
  store.dispatch('setIsTerminal', true);
  store.dispatch('user/setTerminalUser', true);
  window.addEventListener('message', (event) => {
    if (event.data.action === 'Slave.Load') {
      const { device } = event.data.data;
      const { betshop } = event.data.data;
      const { company } = event.data.data;
      const { account } = event.data.data;
      if (event.data.data.settings) {
        store.dispatch('setTerminalMouseCursorEnabled', event.data.data.settings.showCursor);
      }
      if (event.data.data.user.type === 'ANONYMOUS') {
        store.dispatch('setUserIsOperator', false);
      }
      if (event.data.data.user.type === 'OPERATOR') {
        store.dispatch('setUserIsOperator', true);
      }
      deviceData = device;
      betshopData = betshop;
      companyData = company;
      accountData = account;
      store.dispatch('setUserBalance', event.data.data.terminal.balance);
      store.dispatch('setTerminalUserData', event.data.data.user);
      if (event.data.data.betslip.blockers.length) {
        store.dispatch('gamesBetslip/setBetslipBlockers', {
          blockers: event.data.data.betslip.blockers,
          type: 'add',
        });
      }
    }
    if (event.data.action === 'Betslip.Blocked') {
      store.dispatch('gamesBetslip/setBetslipBlockers', {
        blockers: event.data.data.blockers,
        type: 'add',
      });
    }
    if (event.data.action === 'Betslip.Unblocked') {
      store.dispatch('gamesBetslip/setBetslipBlockers', {
        blockers: event.data.data.blockers,
        type: 'remove',
      });
    }
    if (has(event.data.data, 'code') && event.data.data.code === 'T_PAYIN_VALIDATE_INSUFFICIENT_FUNDS') {
      store.dispatch('gamesBetslip/setIsPayinInProgress', false);
      store.dispatch('gamesBetslip/setIsPayinButtonDisabled', false);
    }
    if (has(event.data.data, 'code') && event.data.data.code === 'T_PASS_CARDS_TERMINAL_LOCKED') {
      store.dispatch('gamesBetslip/setIsPayinInProgress', false);
      store.dispatch('gamesBetslip/setIsPayinButtonDisabled', false);
      store.dispatch('bettingDisable', false);
    }
    if ((has(event.data.data, 'code') && event.data.data.code === 'error') || ((has(event.data.data, 'code')) && event.data.data.code === 'UNKNOWN') || ((has(event.data.data, 'code')) && event.data.data.code === 'T_PAYIN_VALIDATION_PRINT_STATUS_ERR')) {
      store.dispatch('gamesBetslip/setIsPayinButtonDisabled', false);
      store.dispatch('gamesBetslip/setIsPayinInProgress', false);
    }
  });
}
const getSlaveId = (id) => {
  if (id === 'TerminalTicketHistory') {
    store.dispatch('setActiveTerminalLayout', 'TerminalTicketHistory');
    return 'PluginLuckySixTicketHistory';
  }
  if (id === 'TerminalResults') {
    store.dispatch('setActiveTerminalLayout', 'TerminalResults');
    return 'PluginLuckySixResults';
  }
  if (id === 'TerminalTicketDetails') {
    store.dispatch('setActiveTerminalLayout', 'TicketCheck');
    return 'PluginLuckySix';
  }
  store.dispatch('setActiveTerminalLayout', 'Terminal');
  return 'PluginLuckySix';
};
const checkLocale = (locale) => {
  switch (locale) {
    case 'sr':
      return 'sr-Latn';
    default:
      return locale;
  }
};
(async () => {
  const configService = new ConfigurationService({
    environment: process.env.NODE_ENV,
    requiredPaths: [],
    applicationName: deliveryPlatform === 'mobile' ? deliveryPlatform : capitalize(deliveryPlatform),
    productName: 'LuckySix',
    channel: deliveryPlatform,
    translationDomains: ['web'],
    messageProtocol,
    clientAdapters: [
      {
        name: `${upperFirst(messageProtocol)}Adapter`,
        options: {
          slaveId: deliveryPlatform === 'terminal' ? getSlaveId(layout) : 'PluginLuckySix',
          eventPropagation: {
            click: true,
            keydown: '*',
          },
        },
      },
    ],
    ui: tenantId,
    deployment,
  });
  const config = await configService.getConfiguration();
  const betslipStaticConfig = config.ui?.config?.betslip;
  const maxNumberOfFutureRounds = config.futureBets.value;
  store.state.gameLayout = lowerFirst(layout) || lowerFirst(config.ui.config.layout) || 'Expanded';
  await store.dispatch('setConfig', config);

  const gamesBetslipConfig = {
    channel: deliveryPlatform,
    betLayout: betslipStaticConfig?.betLayout ?? 'A',
    ticketTypes: betslipStaticConfig?.ticketTypes ?? ['single'],
    rules: config.rules,
    taxes: config.taxes,
    futureRounds: range(1, maxNumberOfFutureRounds + 1),
    isPossibleWinActive: false,
    isPaymentBetAllowed: betslipStaticConfig?.isPaymentBetAllowed ?? true,
    isPaymentPerBetAllowed: betslipStaticConfig?.isPaymentPerBetAllowed ?? true,
    isFuturePerBetAllowed: betslipStaticConfig?.isFuturePerBetAllowed ?? false,
    isFuturePerTicketAllowed: config.futureBet.value,
    isTicketCancelAllowed: betslipStaticConfig?.isTicketCancelAllowed ?? true,
    isTicketRebetAllowed: betslipStaticConfig?.isTicketRebetAllowed ?? true,
    minIncrement: betslipStaticConfig?.minIncrement ?? 0.01,
    isAddButtonActive: true,
    futureRoundsOrientation: window.innerHeight < 700 ? 'top' : 'bottom',
  };
  await store.dispatch('gamesBetslip/setConfig', gamesBetslipConfig);
  await store.dispatch('setDeviceLayout');
  await store.dispatch('setRules', config.rules);
  store.state.config.productUuid = productUuid;

  const translations = await gamesStatic.getTranslations(checkLocale(config.locale));
  const localTranslations = (await i18n.translations(checkLocale(config.locale))).default;
  await store.dispatch('setTranslations', assign(config.translations, translations, localTranslations));
  if (config.applicationName === 'Terminal') {
    await store.dispatch('setGameChannelType', 'Terminal');
  }
  if (token) {
    if (config.ui.config.mode === 'standalone') {
      store.state.config.platformConfig.clientType = clientType;
      store.state.config.platformConfig.playerId = playerId;
      await store.dispatch('getPlayer', { config, token });
    }
    await store.dispatch('user/setUserSessionToken', token);
  }
  if (deliveryPlatform === 'terminal') {
    store.state.config.device = deviceData;
    store.state.config.betshop = betshopData;
    store.state.config.companyData = companyData;
    store.state.config.account = accountData;
  }

  if (process.env.VUE_APP_SENTRY_ACTIVE === 'true') {
    sentry.start(Vue, process.env.VUE_APP_SENTRY_DSN, {
      sampleRate: 0.01,
      trackComponents: true,
      ignoreErrors: [
        'ResizeObserver loop limit exceeded',
        'Network Error',
        'timeout of 5000ms exceeded',
        'timeout of 10000ms exceeded',
        'Android is not defined',
        /.*NotAllowedError.*/gm,
        /.*WakeLock.*/gm,
      ],
    });
  }
  const vm = new Vue({
    router,
    store,
    beforeCreate() {
      if (webViewIntegration.isActive()) {
        // webViewIntegration.init();
        if (window.WebAppListener) {
          window.WebAppListener.sendMessage('Slave.Init');
        }
      }
    },
    render: (h) => h(App),
  });
  vm.$mount('#app');
})();
